"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const action_1 = require("@src/app/services/action");
const tableSize = 10;
class PerfectsquaresCtrl {
    constructor($scope, actionService) {
        this.$scope = $scope;
        this.actionService = actionService;
        this.numbers = [...Array(10).keys()]
            .filter((item) => item >= 1)
            .map((item) => ({ value: item + 1, enabled: true }));
        this.matrix = [];
        this.point = { rowIndex: undefined, cellIndex: undefined };
        this.resultCell = undefined;
        this.kind = 'table';
    }
    $onInit() {
        for (const row_index of [...Array(tableSize).keys()]) {
            let row = [];
            for (const cell_index of [...Array(tableSize).keys()]) {
                const value = parseInt(`${row_index}${cell_index}`);
                const square = Math.pow(value, 2);
                row.push({
                    value: value,
                    square: square,
                    isSquare: cell_index == row_index
                });
            }
            this.matrix.push(row);
        }
    }
    $postLink() {
        const table = document.getElementsByClassName('widget__times');
        if (table) {
            (0, rxjs_1.fromEvent)(table, 'mouseover').pipe((0, operators_1.debounceTime)(10), (0, operators_1.tap)((e) => {
                const td = e.target;
                const tr = td.parentNode;
                this.$scope.$apply(() => {
                    if ((tr.rowIndex > 0) && (td.cellIndex > 0)) {
                        this.point = {
                            rowIndex: tr.rowIndex - 1,
                            cellIndex: td.cellIndex - 1
                        };
                        //@ts-ignore
                        this.resultCell = this.matrix[this.point.rowIndex][this.point.cellIndex];
                    }
                    else {
                        this.point = { rowIndex: undefined, cellIndex: undefined };
                        this.resultCell = undefined;
                    }
                });
            }), (0, operators_1.switchMap)(() => {
                return (0, rxjs_1.fromEvent)(table, 'mouseleave').pipe((0, operators_1.tap)((e) => {
                    this.$scope.$apply(() => {
                        this.point = { rowIndex: undefined, cellIndex: undefined };
                        this.resultCell = undefined;
                    });
                }));
            })).subscribe();
        }
    }
    submitForm($form) {
        this.result = undefined;
        if ($form.$valid && this.number != undefined) {
            this.result = {
                number: this.number,
                square: Math.pow(this.number, 2)
            };
        }
    }
    clear($form) {
        $form.$setPristine();
        $form.$setUntouched();
        this.result = undefined;
        this.number = undefined;
    }
    copy(id) {
        if (this.result) {
            this.actionService.copyFactory(document.getElementById(id), this.result.square);
        }
    }
}
PerfectsquaresCtrl.$inject = ['$scope', 'ActionService',];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gamePerfectsquares', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: PerfectsquaresCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('perfectsquares/');
    }]);
